@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: WorkSans;
  src: url("./Assets/Fonts/WorkSans.ttf");
}

@font-face {
  font-family: WorkSans;
  font-weight: 600;
  src: url("./Assets/Fonts/WorkSans-Medium.ttf");
}

@font-face {
  font-family: WorkSans;
  font-weight: 700;
  src: url("./Assets/Fonts/WorkSans-SemiBold.ttf");
}

@font-face {
  font-family: WorkSans;
  font-weight: 800;
  src: url("./Assets/Fonts/WorkSans-Bold.ttf");
}

:root {
  --hero-bg: #1F1D1B;
  --active-link-color: #FFA338;
  --button-bg: #d16f00;
  --bg-light: #FFE3C3;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  overflow-x: hidden;
  scroll-behavior: smooth;

  body {
    overflow-x: hidden;
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(193, 193, 193);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7d7d7d;
}